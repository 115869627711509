/**
 * This is a page for new assessment
 */
import React, { useEffect } from 'react'
import * as Survey from 'survey-react'
import { get } from 'lodash'
import { useMutation } from 'utils/apollo'
import { Container } from 'components'
import Header from './components/Header'
import { useSnackbar } from 'notistack'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ACTIONS, INTAKE_CSS, SURVEY_CSS } from './utils/constants'

import 'survey-react/modern.css'
import './styles/survey.css'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { CREATE_SESSION } from './constants/gql'
import { getAssessmentScore } from './utils/get-assessment-score'
import { useDispatch, useSelector } from 'react-redux'
import { parseMetadata } from './utils/parse-metadata'
import { resetFilter } from 'store/modules/assessments'

Survey.StylesManager.applyTheme('modern')
Survey.surveyLocalization.getCurrentStrings().progressText = '{0} of {1}'

// CONSTANTS
const { COMPLETED, IN_PROGRESS, SENT_TO_CLIENT } = ASSESSMENT_STATUS

const HeaderTitle = ({ name }) => (
  <span>
    New Assessment | <span className="text-link font-semibold">{name}</span>
  </span>
)
export default function CreateAssessment() {
  // init
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    setLoadingStateWithTimer,
    onLoadKPI,
    setHideHeader,
    setTitle,
    refetch,
  } = useOutletContext()

  // get params variables
  const { userId, productEventId: _productEventId } = useParams()
  const productEventId = parseInt(_productEventId)

  // get metadata
  const productEvents = useSelector((state) => state.assessments.productAssessments)
  const productId = get(
    productEvents.find(({ id }) => id === productEventId),
    'productId',
    null
  )
  const metadata = get(
    productEvents.find(({ id }) => id === productEventId),
    'metadata',
    {}
  )
  const { survey, assessmentName, isIntakeForm } = parseMetadata(metadata)

  // gql
  const [createSession] = useMutation(CREATE_SESSION)

  // if first page, don't show next for intake forms
  const model = new Survey.Model(survey)
  model.showNavigationButtons = model.isFirstPage && isIntakeForm ? 'none' : 'bottom'

  // surveyJS does not know the correct progress text unless we specifically specify it
  const handleProgressText = (sender, options) => {
    // eslint-disable-next-line
    options.text = options.text
  }

  const onAfterRenderQuestionInput = (sender, options) => {
    // show nav button after input
    options.htmlElement.onclick = (click) => {
      const targetClassName = get(click, 'target.className', null)
      if (targetClassName === 'sv-visuallyhidden') {
        const parsedInt = parseInt(click.target.value)
        model.data = { ...model.data, [click.target.name]: isNaN(parsedInt) ? false : parsedInt }
        if (!isIntakeForm) {
          if (model.isLastPage) {
            model.doComplete()
          } else {
            model.nextPage()
          }
        } else {
          const clickValue = get(click, 'target.value', null)
          if (clickValue === 'Start') {
            model.nextPage()
          }
        }
      }
    }
  }

  const onClick = (status) => async () => {
    await setLoadingStateWithTimer(true)
    const score = getAssessmentScore(model.data, metadata)
    const data = { answers: { ...model.data, score }, status, currentPageNo: model.currentPageNo }

    // if there are no userId, then grab from url (eg. `/assessments/create/:userId/:productEventId`)
    try {
      const session = {
        data: JSON.stringify(data).replace(/'/g, '`'),
        productId,
        productEventId,
        startedAt: new Date().toISOString(),
        type: 'answers',
        userId: parseInt(userId) || parseInt(window.location.pathname.split('/').at(3)),
        metadata: { userAgent: navigator.userAgent },
      }
      await createSession({ variables: { session } })
      await refetch()
      await onLoadKPI()
      enqueueSnackbar(ACTIONS[status].message, {
        variant: ACTIONS[status].variant,
        action: CloseSnackbarAction,
      })
    } catch (error) {
      enqueueSnackbar(ACTIONS['error'].message, {
        variant: ACTIONS['error'].variant,
        action: CloseSnackbarAction,
      })
      console.error(error)
    } finally {
      setLoadingStateWithTimer(false)
      dispatch(resetFilter())
      navigate(`/assessments`, { state: { hideGettingStarted: true } })
    }
  }

  // get user info
  const users = useSelector((state) => get(state, 'assessments.userData', []))
  const { fullName, email } = users.find(({ id }) => parseInt(userId) === id) ?? {}
  const name = email ? `${fullName} (${email})` : fullName

  useEffect(() => {
    setHideHeader(true)
    setTitle(<HeaderTitle name={name} />)
    //eslint-disable-next-line
  }, [name])

  return (
    <Container data-test="new-assessment-question-container" className="py-3 overflow-hidden">
      <Header
        onSend={onClick(SENT_TO_CLIENT)}
        onSave={onClick(IN_PROGRESS)}
        title={assessmentName}
      />
      <Survey.Survey
        model={model}
        onComplete={onClick(COMPLETED)}
        questionsOnPageMode="questionPerPage"
        onAfterRenderQuestionInput={onAfterRenderQuestionInput}
        onCurrentPageChanged={(sender, options) => {
          model.showNavigationButtons = model.isFirstPage && isIntakeForm ? 'none' : 'bottom'
        }}
        css={isIntakeForm ? INTAKE_CSS : SURVEY_CSS}
        onProgressText={handleProgressText}
      />
      <div style={{ height: '100px' }} />
    </Container>
  )
}
