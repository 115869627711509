/**
 * Need to refactor assessment table due to original table not refreshing from state change..
 */
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Table, TableBody, TableCell, TableRow, Grid, Box, Collapse, TableFooter } from 'components'

import AssessmentDetails from 'views/assessments/components/AssessmentDetails'
import { useOutletContext } from 'react-router'
import { EnhancedTableHead } from './enhanced-table-toolbar'

//icons
import { CustomTablePagination } from './custom-table-pagination'

import { HEAD_CELLS } from './data'

export default function AssessmentTableComponent({
  columns = [],
  loading,
  selectedRow: _selectedRow,
  onSelectRow = () => null,
}) {
  const [selectedRow, selectRow] = useState(null)
  const scrollToAssesmentBottomRef = useRef(null)

  /**
   * Use line below once we implement filter at query level
   * const data = useSelector((state) => get(state, 'assessments.sessions', []))
   */
  const {
    data: visibleRows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    totalAssessmentsCount,
    order,
    orderBy,
    handleRequestSort,
  } = useOutletContext()

  useEffect(() => {
    selectRow(_selectedRow)
  }, [_selectedRow])

  useEffect(() => {
    if (selectedRow === visibleRows.length - 1) {
      scrollToAssesmentBottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedRow])
  const defaultLabelDisplayedRows = ({ from, to, count }) => {
    return `Displaying ${from}–${to} of ${count !== -1 ? count : `more than ${to}`} Rows`
  }

  return (
    <Box p={2}>
      <Table sx={{ overflowX: 'auto', width: '100%' }} data-test="assessments-table">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={HEAD_CELLS}
        />
        <TableBody data-test="table-body">
          {!visibleRows.length && !loading && (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Grid container justifyContent="center" className="fill-width p-5">
                  No data to display
                </Grid>
              </TableCell>
            </TableRow>
          )}
          {visibleRows?.map((row, rowIndex) => {
            const isSelected = selectedRow === rowIndex
            const isLastRow = rowIndex === visibleRows.length - 1

            return (
              <Fragment key={`data${rowIndex}`}>
                <TableRow
                  data-test={`table-row${rowIndex}`}
                  hover
                  selected={isSelected}
                  // className={styles.row}
                  onClick={() => {
                    const selected = isSelected ? null : rowIndex
                    selectRow(selected)
                    onSelectRow(selected)
                  }}
                  sx={{
                    '& > *': {
                      // important is used here to overidde default tablecell CSS
                      borderBottom: 'unset !important',
                      borderTop: 'unset',
                    },
                    '& .MuiTableRow-hover': {
                      backgroundColor: '#f2f8f7 !important',
                    },
                    cursor: 'pointer',
                  }}
                >
                  {columns.map(({ body: Cell = () => null }, i) => (
                    <TableCell key={`data${rowIndex}${i}`}>
                      <Cell
                        data={row}
                        isSelected={isSelected}
                        isLastRow={i === columns.length - 1}
                      />
                    </TableCell>
                  ))}
                </TableRow>

                <TableRow
                  hover
                  sx={{
                    '& > *': {
                      borderTop: 'unset',
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    },
                    backgroundColor: '#f2f8f7 !important',
                    // this is the current height of expanded row
                    height: isLastRow && isSelected ? '53px' : 'inherit',
                  }}
                >
                  <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
                    {isLastRow && <div ref={scrollToAssesmentBottomRef} />}
                    <Collapse in={isSelected} timeout="auto" unmountOnExit>
                      <AssessmentDetails data={row} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </Fragment>
            )
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <CustomTablePagination
              defaultLabelDisplayedRows={defaultLabelDisplayedRows}
              totalAssessmentsCount={totalAssessmentsCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              colSpan={6}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  )
}
